#projects {
  background-image: url('../../public/prism-background.svg');
  /*background by SVGBackgrounds.com*/
  background-size: 500px;
  background-position: 0 0;
  background-repeat: repeat;
  position: relative;
  z-index: 1;

  padding: 4rem;

  overflow: hidden; /* Prevents scrollbar overflow */
}

#projects .heading-effect {
  margin-left: 3rem;
}

/* Project Grid */
.projects-grid {
  display: flex;
  flex-direction: column;
}

/* Project Card - Flex layout for description and image */
.project-card {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  overflow: hidden;
  margin: 1rem;
  text-decoration: none;
  color: inherit;
}

/* Remove hover effect from project card */
.project-card:hover {
  transform: none;
}

/* Project Image Wrapper */
.project-image-wrapper {
  width: 70%; /* Takes up half the space by default */
  height: 100%; /* Ensure it fills the available height */
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

/* Add hover effect to image wrapper */
.project-image-wrapper:hover {
  transform: translateY(-2.5px); /* Changed from -5px to -2.5px */
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Change hover selector to target image wrapper instead of project card */
.project-image-wrapper:hover .project-image {
  transform: scale(1.025); /* Changed from 1.05 to 1.025 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Reduced shadow size */
}

/* Project Info */
.project-info {
  width: 30%; /* Takes up half the space by default */
  padding: 1.5rem;
  background-color: rgba(90, 90, 90, 0);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.project-info h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #ffffff;
}

.project-info p {
  margin: 0.5rem 0 1rem;
  color: #afafaf;
  font-size: 1rem;
}

/* Mobile View - Stack vertically */
@media (max-width: 768px) {
  #projects {
    padding: 0.5rem;
  }

  #projects .heading-effect {
    margin-left: 0rem;
  }

  .project-card {
    flex-direction: column; /* Stack image and description */
  }

  .project-image-wrapper,
  .project-info {
    width: 100%; /* Each section takes full width */
  }
}
