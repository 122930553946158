.project-not-found {
  text-align: center;
  padding: 6rem 2rem 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.project-not-found h1 {
  color: #dc3545;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.project-not-found p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.project-detail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.project-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.project-header h1 {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.project-header img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.media-container {
  display: flex;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;
}

.project-header img,
.video-container {
  flex: 1;
  min-width: 300px;
  max-width: calc(50% - 1rem);
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.video-container {
  position: relative;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.project-content {
  width: 100%;
  max-width: 800px;
}

.project-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 2rem;
}

.project-technology {
  margin-bottom: 2rem;
}

.project-technology h2 {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.project-technology ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.project-technology li {
  background-color: #007bff;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #2c3e50;
}

.project-technology li:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.project-link,
.project-in-progress {
  display: inline-flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0.75rem 1.25rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project-link:hover,
.project-in-progress:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  color: white;
}

.in-progress-icon {
  font-size: 1.2rem;
  margin-right: 0.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.project-in-progress p {
  margin: 0;
}

.project-header img.with-video {
  flex: 1;
  min-width: 300px;
  max-width: calc(50% - 1rem);
}

@media (max-width: 768px) {
  .project-detail {
    padding: 60px 15px;
  }

  .project-header h1 {
    font-size: 2rem;
  }

  .project-content p {
    font-size: 1rem;
  }

  .media-container {
    flex-direction: column;
    gap: 1rem;
  }

  .project-header img,
  .video-container {
    max-width: 100%;
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  .project-header img.with-video,
  .project-header img.full-width {
    width: 100%;
    max-width: 100%;
  }

  .project-link,
  .project-in-progress {
    width: 100%;
    justify-content: center;
  }
}

.content-wrapper {
  display: flex;
  gap: 2rem;
}

.description {
  flex: 0 0 110%;
}

.project-technology {
  flex: 0 0 40%;
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .description,
  .project-technology {
    flex: 0 0 100%;
  }
}

.project-header img.full-width {
  width: 100%;
  max-width: 100%;
}
